import { v4 } from 'uuid'

export const state = () => ({
  projectState: null,
  token: null,
  role: null,
  tezos: null,
  wallet: null,
  userAddress: null,
  userAlias: null,
  userTokens: {
    projects: [],
    tokens: [],
    total: 0,
    address: null,
    timestamp: null,
    limit: 50,
    offset: 0,
  },
  selectedProject: null,
  userTokenAddress: null,
  userTokensTimestamp: null,
  crowdsales: null,
  balance: 0,
  snackbars: [],
  marketOptions: {
    sort: 'listed-desc',
    filters: [],
  },
  prefs: {
    collapseSmiths: false,
    collapseWidget: false,
    crowdsalesSetting: true,
    collectionFilters: {
      sort: 'updated_at-desc',
      filters: [],
    },
    showTopUpPopUp: true,
    vueTour: true,
    vueTourStep: 0,
    vueTourSandbox: true,
    vueTourStepSandbox: 0,
    widget_app_radius: 10,
    widget_app_bg_color: '#FFFFFF',
    widget_text_color: '#000000',
    widget_btn_radius: 20,
    widget_btn_bg_color: '#6C2BC2',
    widget_btn_text_color: '#FFFFFF',
  },
})

export const getters = {
  isAuthenticated(state) {
    return state.auth.loggedIn
  },
  getUserInfo(state) {
    return state.auth.user
  },
  wallet: (state) => {
    return state.wallet
  },
  tezos: (state) => {
    return state.tezos
  },
  userAddress: (state) => {
    return state.userAddress
  },
  balance: (state) => {
    return state.balance
  },
  crowdsales: (state) => {
    return state.crowdsales
  },
}

export const actions = {
  async loadUsersTokens({ commit, state }, userAddress) {
    try {
      console.log(
        `Loading users tokens for ${userAddress} / ${state.selectedProject}...`,
        state.userTokens.limit,
        state.userTokens.offset
      )
      const request = {
        method: 'GET',
        url: `/api/tokens/wallet/` + userAddress,
        params: {
          limit: state.userTokens.limit,
          offset: state.userTokens.offset,
        },
      }
      if (state.selectedProject) {
        request.params.projectId = state.selectedProject
      }
      const resp = await this.$axios(request)
      commit('SET_USER_TOKENS', {
        tokens:
          state.userTokens.offset > 0
            ? [].concat(state.userTokens.tokens, resp.data.tokens)
            : resp.data.tokens,
        total: resp.data.total,
        address: userAddress,
        timestamp: new Date().toISOString(),
        limit: parseInt(resp.data.limit),
        offset: parseInt(resp.data.offset),
        projects: resp.data.projects,
      })
      console.log('loaded user tokens', resp.data.tokens)
      return resp.data.tokens
    } catch (e) {
      console.error('Failed to load users tokens', e)
    }
  },
  setProjectState({ commit }, payload) {
    commit('SET_STATE', payload)
  },
  addSnackbar({ commit }, snackbar) {
    snackbar.id = v4()
    snackbar.showing = true
    switch (snackbar.type) {
      case 'info':
        snackbar.color = '#0684FE'
        break
      case 'error':
        snackbar.color = '#F4541D'
        break
      default:
        snackbar.color = '#37AB80'
        break
    }

    // snackbar.timeout = snackbar.timeout || 6000
    commit('ADD_SNACKBAR', snackbar)
    if (snackbar.timeout > 0) {
      setTimeout(() => {
        commit('HIDE_SNACKBAR', snackbar.id)
      }, snackbar.timeout)
    }
  },
  hideSnackbar({ commit }, snackbarId) {
    commit('HIDE_SNACKBAR', snackbarId)
  },
  async logTerms({ commit }, { wallet, version }) {
    try {
      await this.$axios({
        method: 'post',
        url: '/api/termsLog',
        data: {
          wallet,
          version,
        },
      })
    } catch (e) {
      console.error(e)
    }
  },
  async loadBalance({ commit }, address) {
    const bal = await this.$globals.tezos.tz.getBalance(address)
    commit('INIT_USER_BALANCE', bal.toNumber())
  },
  setMarketOptions({ commit }, payload) {
    commit('SET_MARKET_OPTIONS', payload)
  },
  setPref({ commit }, payload) {
    commit('SET_PREF', payload)
  },
}

export const mutations = {
  SET_PREF(state, { pref, value }) {
    console.log(pref)
    console.log(value)

    state.prefs[pref] = value
  },
  SET_MARKET_OPTIONS(state, data) {
    state.marketOptions = data
  },
  SET_SELECTED_PROJECT(state, data) {
    state.selectedProject = data
  },
  SET_USER_TOKENS(state, data) {
    state.userTokens = data
  },
  ADD_SNACKBAR(state, snackbar) {
    state.snackbars = state.snackbars.concat(snackbar)
  },
  HIDE_SNACKBAR(state, snackbarId) {
    const idx = state.snackbars.findIndex((s) => s.id === snackbarId)
    if (idx > -1) {
      state.snackbars[idx].showing = false
    }
  },
  SET_TOKEN(state, value) {
    state.token = value
  },
  SET_ROLE(state, value) {
    state.role = value
  },
  INIT_TEZOS(state, value) {
    state.tezos = value
  },
  INIT_BEACON(state, value) {
    state.wallet = value
  },
  INIT_CROWDSALES(state, value) {
    state.crowdsales = value
  },
  INIT_USER_WALLET(state, { wallet, alias }) {
    state.userAddress = wallet
    state.userAlias = alias
  },
  INIT_USER_BALANCE(state, value) {
    state.balance = value
  },
}
