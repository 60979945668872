//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    user: null,
    cards: ['Today', 'Yesterday'],
    drawer: null,
    links: [
      // ['mdi-chart-box', 'Dashboard', '/admin/dashboard'],
      ['mdi-palette', 'Projects','/admin/projects'],
      ['mdi-account-multiple', 'Artists', '/admin/artists'],
      ['mdi-account-group', 'Clients', '/admin/clients'],
      ['mdi-account-supervisor-circle', 'Administrators', '/admin/administrators'],
    ],
  }),
  computed: {
    email() {
      const user = this.$store.getters.getUserInfo
      return user.email
    }
  },
  methods: {

  }
}
