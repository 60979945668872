import { BeaconEvent, defaultEventCallbacks } from '@airgap/beacon-sdk'
import { BeaconWallet } from '@taquito/beacon-wallet'
import { TezosToolkit, MichelCodecPacker } from '@taquito/taquito'
import { Tzip16Module } from '@taquito/tzip16'
import { RpcClient, RpcClientCache } from '@taquito/rpc'
import { ContractsLibrary } from '@taquito/contracts-library'
import { TaquitoTezosDomainsClient } from '@tezos-domains/taquito-client'
import extend from '../utils/extend-vue-app'
export default function (context) {
  const { app, store, route } = context
  extend(app, {
    async mounted() {
      const { tezosRpcs, tezosNetwork } = app.$config
      try {
        const tezosRpc = tezosRpcs[Math.floor(tezosRpcs.length * Math.random())]
        console.log(`Connecting to tezos at ${tezosRpc}`)
        const rpcClient = new RpcClient(tezosRpc)
        const tezos = new TezosToolkit(new RpcClientCache(rpcClient))
        tezos.addExtension(new Tzip16Module())

        const tzDomains = new TaquitoTezosDomainsClient({
          tezos,
          network: tezosNetwork,
          caching: { enabled: true },
        })

        const options = {
          name: 'ArtForge',
          // iconUrl: 'https://www.pxl.games/img/logo-light.7a29fa0f.png',
          preferredNetwork: tezosNetwork,
          network: {
            type: tezosNetwork,
            rpcUrl: tezosRpc,
          },
          eventHandlers: {
            [BeaconEvent.PAIR_INIT]: {
              handler: defaultEventCallbacks.PAIR_INIT,
            },
            [BeaconEvent.PAIR_SUCCESS]: {
              handler: defaultEventCallbacks.PAIR_SUCCESS,
            },
          },
        }
        const wallet = new BeaconWallet(options)
        const activeAccount = await wallet.client.getActiveAccount()
        let userAddress = activeAccount ? await wallet.getPKH() : null

        if (store.getters.getUserInfo?.wallet == null) {
          if (wallet) {
            wallet.client.disconnect()
            userAddress = null
          }
        }

        if (userAddress) {
          tezos.setWalletProvider(wallet)
          store.commit('INIT_USER_WALLET', { wallet: userAddress, alias: null })
          if (route.name !== 'profile-profileId') {
            store.dispatch('loadUsersTokens', userAddress)
          }
          const alias = store.getters.getUserInfo?.username ?? ''

          store.commit('INIT_USER_WALLET', { wallet: userAddress, alias })

          const balance = await tezos.tz.getBalance(userAddress)
          store.commit('INIT_USER_BALANCE', balance.toNumber())
        }

        // set up contracts library
        const contracts = await app
          .$content('contracts')
          .where({ network: tezosNetwork })
          .fetch()

        if (contracts !== null && contracts?.length > 0) {
          const contractsLibrary = new ContractsLibrary()
          contracts.forEach((contract) => {
            const contractData = {}
            contractData[`${contract.slug}`] = {
              script: contract.script,
              entrypoints: contract.entrypoints,
            }
            contractsLibrary.addContract(contractData)
          })
          tezos.addExtension(contractsLibrary)
        }

        tezos.setPackerProvider(new MichelCodecPacker())

        app.$globals.tezos = tezos
        app.$globals.wallet = wallet
        app.$globals.tzDomains = tzDomains
        console.log('WALLET CONNECTED')
      } catch (e) {
        console.error('Unable to init taquito', e)
        await new Promise((resolve) => setTimeout(resolve, 15000))
        // this.$router.go(0)
      }
    },
  })
}
