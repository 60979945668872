//
//
//
//
//
//

import HomeView from '@/components/layout/Homeview'

  export default {
    name: 'AdminLandingLayout',
    components: {
      HomeView,
    }
  }
