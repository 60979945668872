export default function ({ store, redirect, route }) {
  console.log(route.params.profileId);
  // If the user is not authenticated

  if (store.getters.getUserInfo.id !== route.params.profileId
    && store.getters.getUserInfo.wallet !== route.params.profileId
    && store.getters.getUserInfo.username !== route.params.profileId) {

    return redirect('/')
  }

  // else {
  //   const user = store.getters.getUserInfo
  //   if (user.role === 'admin') {
  //     return redirect('/admin/dashboard')
  //   } else {
  //     return redirect('/')
  //   }
  // }
}
