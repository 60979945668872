//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      monitorLoop: false,
      curBalance: null,
      loading: false,
    }
  },
  computed: {
    userAddress() {
      return this.$store.getters.userAddress
    },
    balance() {
      return this.$store.getters.balance
    },
  },
  methods: {
    handleOpen() {
      this.dialog = true
    },
    loadTopUpModule() {
      this.$refs.topUpDialog.handleOpen(null, this.balance)
      this.dialog = false
    },
    dontAskAgain() {
      this.$store.dispatch('setPref', {
        pref: `showTopUpPopUp`,
        value: false,
      })
      this.dialog = false
    },
  },
}
