//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: String,
      default: '',
    },
    activator : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    handleOpen() {
      this.dialog = true
    },
  },
}
