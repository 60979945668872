//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Appbar from '@/components/layout/Appbar'
import HomeView from '@/components/layout/Homeview'
import Footer from '@/components/layout/Footer'
export default {
  name: 'DefaultHomeLayout',
  components: {
    Appbar,
    HomeView,
    Footer,
  },
  computed: {
    ...mapState(['snackbars']),
  },
}
