//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {debounce as _debounce} from 'lodash'

  export default {
    data: () => ({
      entries: [],
      panel: [0],
      isLoading: false,
      model: null,
      search: null,
      tests: [
        {
          icon: 'mdi-inbox',
          text: 'Inbox',
        },
        {
          icon: 'mdi-star',
          text: 'Star',
        },
        {
          icon: 'mdi-send',
          text: 'Send',
        },
        {
          icon: 'mdi-email-open',
          text: 'Drafts',
        },
      ],
    }),

    computed: {
      items() {
        return this.entries
      }
    },

    watch: {
      search (val) {
        this.debounceFetch()
      },
    },

    methods: {
      async fetchCollection() {
        try {
          if (this.search) {
            this.isLoading = true
            const resp = await this.$axios.$get(
              `/api/projects/search?term=${this.search}&limit=9999`
            )
            this.count = resp.total
            this.entries = resp.projects
            this.isLoading = false
          } else {
            this.entries = []
          }
        } catch (e) {
          this.isLoading = false
          console.log(e)
        }
      },
      debounceFetch: _debounce(function() {
        return this.fetchCollection()
      }, 300)
    }
  }
