import { render, staticRenderFns } from "./error.vue?vue&type=template&id=1315ad9e&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImg: require('/tmp/build_643efac4/components/base/Img.vue').default,BaseHeading: require('/tmp/build_643efac4/components/base/Heading.vue').default,BaseSubheading: require('/tmp/build_643efac4/components/base/Subheading.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VContainer})
