export const state = () => ({
  dirHandle: null,
  artwork: null,
})

export const mutations = {
  SET_DIR_HANDLE(state, data) {
    state.dirHandle = data
  },
  SET_ARTWORK(state, data) {
    state.artwork = data
  },
}
