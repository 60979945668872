//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ToggleTheme from '@/components/ToggleTheme'
import LoginSignup from '@/components/LoginSignup'
import UserMenu from '@/components/UserMenu'

export default {
  name: 'AdminAppBar',

  components: {
    LoginSignup,
    UserMenu,
    ToggleTheme
  },

  data: () => ({
    drawer: null,
  }),
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;  // it check if user isAuthenticated 
    },
    getUserInfo() {
      return this.$store.getters.getUserInfo;
    }
  },

  methods: {
    scrollToElement(options) {
      document.getElementById('recent-works').scrollIntoView({
      behavior: "smooth"
    });
    },
    async logout() {
      await this.$auth.logout();  // this method will logout the user and make token to false on the local storage of the user browser
    }
  },
}
