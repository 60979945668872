var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"px-0 px-xl-16",attrs:{"absolute":"","color":"transparent","elevation":"0","height":"80"}},[_c('v-row',{staticClass:"px-0 px-lg-4"},[_c('v-col',{staticClass:"hidden-md-and-down",attrs:{"lg":"12","xs":"12"}},[_c('v-toolbar',{attrs:{"color":"rgba(0, 0, 0, 0.5)","elevation":"4"}},[_c('NuxtLink',{attrs:{"to":"/"}},[_c('img',{staticClass:"mr-3 hidden-sm-and-down",attrs:{"src":require("assets/Logo Horizontal Color_Beta.png"),"contain":"","width":"350"}})]),_vm._v(" "),_c('div',{staticClass:"search-bar mx-auto"},[_c('SearchBar',{staticClass:"pt-n1"})],1),_vm._v(" "),_c('v-btn',{staticClass:"nfty-base-btn btn-transparent",attrs:{"height":"40","text":"","to":"/collections"}},[_vm._v("\n            Explore\n          ")]),_vm._v(" "),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"nfty-base-btn hidden-sm-and-down",attrs:{"height":"40","text":""}},'v-btn',attrs,false),on),[_vm._v("\n                  Community\n                  "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-chevron-down")])],1)]}}])},[_vm._v(" "),_c('v-list',_vm._l((_vm.dropdown),function(ref){
var type = ref[0];
var name = ref[1];
var url = ref[2];
return _c('v-list-item',{key:name,attrs:{"to":type === 'internal' ? url : '',"href":type === 'external' ? url : '',"target":"_blank"}},[_c('v-list-item-title',{staticClass:"nfty-drawer-text"},[_vm._v("\n                    "+_vm._s(name)+"\n                  ")])],1)}),1)],1)],1),_vm._v(" "),_c('v-btn',{staticClass:"mx-4 nfty-btn-secondary nfty-base-btn mr-6",attrs:{"ripple":false,"styles":'margin-top: -7px',"to":"/artists"}},[_vm._v("\n            Create\n          ")]),_vm._v(" "),(_vm.userAddress === null)?_c('wallet-dialog',{attrs:{"connect-wallet":_vm.connectWallet}}):(_vm.userAddress !== null)?_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":200,"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"\n                  mr-2\n                  nfty-btn-secondary nfty-base-btn\n                  hidden-sm-and-down\n                ",attrs:{"dark":""}},'v-btn',attrs,false),on),[(_vm.userAvatar !== '' && _vm.userAvatar !== null)?_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"primary","size":"32"}},[_c('v-img',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":_vm.userAvatar}})],1):_c('v-img',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":("https://services.tzkt.io/v1/avatars/" + _vm.userAddress)}}),_vm._v(" "),(_vm.mouseover == false)?_c('span',[_vm._v(_vm._s(_vm.formattedAddr))]):_vm._e()],1)]}}])},[_vm._v(" "),_c('ConnectedUserDialog',{attrs:{"balance":_vm.balance,"user-address":_vm.userAddress,"user-alias":_vm.userAlias},on:{"disconnectWallet":_vm.disconnectWallet,"connectWallet":_vm.connectWallet,"loadTopUpModule":_vm.loadTopUpModule}})],1):_vm._e()],1)],1),_vm._v(" "),_c('v-col',{staticClass:"hidden-lg-and-up",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-app-bar-nav-icon',{staticClass:"justify-center ma-auto hidden-lg-and-up",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" $vuetify.icons.menu")])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('div',{staticClass:"logoWidth"},[_c('NuxtLink',{attrs:{"to":"/"}},[_c('base-img',{staticClass:"hidden-lg-and-up",attrs:{"src":require('@/assets/Logo Square Color_Beta.png'),"contain":"","max-width":"80","width":"100%"}})],1)],1),_vm._v(" "),(_vm.userAddress !== null)?_c('v-spacer'):_vm._e(),_vm._v(" "),(_vm.userAddress !== null)?_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":200,"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"\n                  mr-2\n                  mt-sm-3\n                  nfty-btn-secondary nfty-base-btn\n                  hidden-lg-and-down\n                ",attrs:{"dark":""}},'v-btn',attrs,false),on),[(_vm.userAvatar !== '' && _vm.userAvatar !== null)?_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"primary","size":"32"}},[_c('v-img',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":_vm.userAvatar}})],1):_c('v-img',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":("https://services.tzkt.io/v1/avatars/" + _vm.userAddress)}}),_vm._v(" "),(_vm.mouseover == false)?_c('span',[_vm._v(_vm._s(_vm.formattedAddr))]):_vm._e()],1)]}}],null,false,2856463557)},[_vm._v(" "),_c('ConnectedUserDialog',{attrs:{"balance":_vm.balance,"user-address":_vm.userAddress,"user-alias":_vm.userAlias},on:{"disconnectWallet":_vm.disconnectWallet,"connectWallet":_vm.connectWallet,"loadTopUpModule":_vm.loadTopUpModule}})],1):_vm._e(),_vm._v(" "),(_vm.userAddress !== null)?_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":200,"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.userAddress)?_c('v-app-bar-nav-icon',_vm._g(_vm._b({staticClass:"justify-center ma-auto hidden-lg-and-up"},'v-app-bar-nav-icon',attrs,false),on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" $vuetify.icons.profile")])],1):_vm._e()]}}],null,false,4049544802)},[_vm._v(" "),_c('ConnectedUserDialog',{attrs:{"balance":_vm.balance,"user-address":_vm.userAddress,"user-alias":_vm.userAlias},on:{"disconnectWallet":_vm.disconnectWallet,"connectWallet":_vm.connectWallet,"loadTopUpModule":_vm.loadTopUpModule}})],1):_c('v-col',[_c('wallet-dialog',{attrs:{"connect-wallet":_vm.connectWallet}})],1)],1)])],1)],1),_vm._v(" "),_c('v-navigation-drawer',{staticClass:"hidden-lg-and-up",attrs:{"absolute":"","temporary":"","color":"black","width":"100%"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":"","color":"black"}},[_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"}},[_c('v-list-item',[_c('v-btn',{attrs:{"plain":"","icon":"","color":"white"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close-circle")])],1)],1),_vm._v(" "),_c('v-list-item',{key:"collections",staticClass:"nfty-drawer-text",attrs:{"to":"/collections","href":"/collections"}},[_c('v-list-item-title',[_vm._v(" Explore ")])],1),_vm._v(" "),_c('v-list-item',{key:"artists",staticClass:"nfty-drawer-text",attrs:{"to":"/artists","href":"/artists"}},[_c('v-list-item-title',{staticStyle:{"color":"white"}},[_vm._v("\n            Create\n          ")])],1),_vm._v(" "),_vm._l((_vm.dropdown),function(ref){
var type = ref[0];
var name = ref[1];
var url = ref[2];
return _c('v-list-item',{key:name,staticClass:"nfty-drawer-text",attrs:{"to":type === 'internal' ? url : '',"href":type === 'external' ? url : ''}},[_c('v-list-item-title',[_vm._v("\n            "+_vm._s(name)+"\n          ")])],1)})],2)],1)],1),_vm._v(" "),_c('CreateDialog',{ref:"createDialog",attrs:{"hidden":true}}),_vm._v(" "),_c('WalletDialog',{ref:"walletDialog",attrs:{"hidden":true}}),_vm._v(" "),_c('HelpWalletDialog',{ref:"helpWalletDialog"}),_vm._v(" "),_c('TopUpPopUp',{ref:"topUpPopUp",attrs:{"hidden":true}}),_vm._v(" "),_c('TopUpDialog',{ref:"topUpDialog",attrs:{"hidden":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }