import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _483b3d24 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _873b61c4 = () => interopDefault(import('../pages/AgreementCrowdSale.vue' /* webpackChunkName: "pages/AgreementCrowdSale" */))
const _6e317eeb = () => interopDefault(import('../pages/artists/index.vue' /* webpackChunkName: "pages/artists/index" */))
const _393e9df9 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _e42afd98 = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _57057c60 = () => interopDefault(import('../pages/crowdsale.vue' /* webpackChunkName: "pages/crowdsale" */))
const _28a5d88f = () => interopDefault(import('../pages/Disclaimer.vue' /* webpackChunkName: "pages/Disclaimer" */))
const _23d182d8 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _d51bec78 = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _f3747434 = () => interopDefault(import('../pages/sandbox/index.vue' /* webpackChunkName: "pages/sandbox/index" */))
const _1653714e = () => interopDefault(import('../pages/service-agreement.vue' /* webpackChunkName: "pages/service-agreement" */))
const _08a3b143 = () => interopDefault(import('../pages/Terms.vue' /* webpackChunkName: "pages/Terms" */))
const _4145234e = () => interopDefault(import('../pages/Terms_202110290648.vue' /* webpackChunkName: "pages/Terms_202110290648" */))
const _15741146 = () => interopDefault(import('../pages/admin/administrators/index.vue' /* webpackChunkName: "pages/admin/administrators/index" */))
const _48e465aa = () => interopDefault(import('../pages/admin/artists/index.vue' /* webpackChunkName: "pages/admin/artists/index" */))
const _27027e27 = () => interopDefault(import('../pages/admin/clients/index.vue' /* webpackChunkName: "pages/admin/clients/index" */))
const _11581a9a = () => interopDefault(import('../pages/admin/dashboard/index.vue' /* webpackChunkName: "pages/admin/dashboard/index" */))
const _206ed6de = () => interopDefault(import('../pages/admin/projects/index.vue' /* webpackChunkName: "pages/admin/projects/index" */))
const _eec752ce = () => interopDefault(import('../pages/archivedPages/me.vue' /* webpackChunkName: "pages/archivedPages/me" */))
const _eff9c5c2 = () => interopDefault(import('../pages/archivedPages/publicproject.vue' /* webpackChunkName: "pages/archivedPages/publicproject" */))
const _0fc44723 = () => interopDefault(import('../pages/archivedPages/signin.vue' /* webpackChunkName: "pages/archivedPages/signin" */))
const _245a9d99 = () => interopDefault(import('../pages/archivedPages/signup.vue' /* webpackChunkName: "pages/archivedPages/signup" */))
const _344e9e02 = () => interopDefault(import('../pages/archivedPages/upload.vue' /* webpackChunkName: "pages/archivedPages/upload" */))
const _3eb197e1 = () => interopDefault(import('../pages/archivedPages/uploadmany.vue' /* webpackChunkName: "pages/archivedPages/uploadmany" */))
const _9719eefa = () => interopDefault(import('../pages/sandbox/Helpers/CheckSupportedBrowser.js' /* webpackChunkName: "pages/sandbox/Helpers/CheckSupportedBrowser" */))
const _14bb27a7 = () => interopDefault(import('../pages/sandbox/Helpers/Config.js' /* webpackChunkName: "pages/sandbox/Helpers/Config" */))
const _a883a2f4 = () => interopDefault(import('../pages/sandbox/Helpers/GetDirectoriesRandomFiles.js' /* webpackChunkName: "pages/sandbox/Helpers/GetDirectoriesRandomFiles" */))
const _ef63c3b8 = () => interopDefault(import('../pages/sandbox/Helpers/GetFirstImageDimension.js' /* webpackChunkName: "pages/sandbox/Helpers/GetFirstImageDimension" */))
const _3dde8e84 = () => interopDefault(import('../pages/sandbox/Helpers/GetImageFromFile.js' /* webpackChunkName: "pages/sandbox/Helpers/GetImageFromFile" */))
const _4578e8c8 = () => interopDefault(import('../pages/sandbox/Helpers/ReadFileSync.js' /* webpackChunkName: "pages/sandbox/Helpers/ReadFileSync" */))
const _71d0384f = () => interopDefault(import('../pages/sandbox/Helpers/UploadFiles.js' /* webpackChunkName: "pages/sandbox/Helpers/UploadFiles" */))
const _0f165a96 = () => interopDefault(import('../pages/admin/artists/_id/index.vue' /* webpackChunkName: "pages/admin/artists/_id/index" */))
const _7032fefc = () => interopDefault(import('../pages/admin/projects/_id/index.vue' /* webpackChunkName: "pages/admin/projects/_id/index" */))
const _3787fcb1 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _1c77e21f = () => interopDefault(import('../pages/collections/_id/index.vue' /* webpackChunkName: "pages/collections/_id/index" */))
const _2e857426 = () => interopDefault(import('../pages/help/_category/index.vue' /* webpackChunkName: "pages/help/_category/index" */))
const _ed20dd3c = () => interopDefault(import('../pages/profile/_profileId/index.vue' /* webpackChunkName: "pages/profile/_profileId/index" */))
const _643e7744 = () => interopDefault(import('../pages/collections/_id/purchase.vue' /* webpackChunkName: "pages/collections/_id/purchase" */))
const _364c81c7 = () => interopDefault(import('../pages/collections/_id/widget.vue' /* webpackChunkName: "pages/collections/_id/widget" */))
const _61169168 = () => interopDefault(import('../pages/profile/_profileId/collections/index.vue' /* webpackChunkName: "pages/profile/_profileId/collections/index" */))
const _597d6ac2 = () => interopDefault(import('../pages/profile/_profileId/vault.vue' /* webpackChunkName: "pages/profile/_profileId/vault" */))
const _27c2430e = () => interopDefault(import('../pages/assets/_collectionId/_assetId/index.vue' /* webpackChunkName: "pages/assets/_collectionId/_assetId/index" */))
const _3ab1fc1a = () => interopDefault(import('../pages/c/_id/_wallet.vue' /* webpackChunkName: "pages/c/_id/_wallet" */))
const _319859a4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _483b3d24,
    name: "admin"
  }, {
    path: "/AgreementCrowdSale",
    component: _873b61c4,
    name: "AgreementCrowdSale"
  }, {
    path: "/artists",
    component: _6e317eeb,
    name: "artists"
  }, {
    path: "/blog",
    component: _393e9df9,
    name: "blog"
  }, {
    path: "/collections",
    component: _e42afd98,
    name: "collections"
  }, {
    path: "/crowdsale",
    component: _57057c60,
    name: "crowdsale"
  }, {
    path: "/Disclaimer",
    component: _28a5d88f,
    name: "Disclaimer"
  }, {
    path: "/help",
    component: _23d182d8,
    name: "help"
  }, {
    path: "/Privacy",
    component: _d51bec78,
    name: "Privacy"
  }, {
    path: "/sandbox",
    component: _f3747434,
    name: "sandbox"
  }, {
    path: "/service-agreement",
    component: _1653714e,
    name: "service-agreement"
  }, {
    path: "/Terms",
    component: _08a3b143,
    name: "Terms"
  }, {
    path: "/Terms_202110290648",
    component: _4145234e,
    name: "Terms_202110290648"
  }, {
    path: "/admin/administrators",
    component: _15741146,
    name: "admin-administrators"
  }, {
    path: "/admin/artists",
    component: _48e465aa,
    name: "admin-artists"
  }, {
    path: "/admin/clients",
    component: _27027e27,
    name: "admin-clients"
  }, {
    path: "/admin/dashboard",
    component: _11581a9a,
    name: "admin-dashboard"
  }, {
    path: "/admin/projects",
    component: _206ed6de,
    name: "admin-projects"
  }, {
    path: "/archivedPages/me",
    component: _eec752ce,
    name: "archivedPages-me"
  }, {
    path: "/archivedPages/publicproject",
    component: _eff9c5c2,
    name: "archivedPages-publicproject"
  }, {
    path: "/archivedPages/signin",
    component: _0fc44723,
    name: "archivedPages-signin"
  }, {
    path: "/archivedPages/signup",
    component: _245a9d99,
    name: "archivedPages-signup"
  }, {
    path: "/archivedPages/upload",
    component: _344e9e02,
    name: "archivedPages-upload"
  }, {
    path: "/archivedPages/uploadmany",
    component: _3eb197e1,
    name: "archivedPages-uploadmany"
  }, {
    path: "/sandbox/Helpers/CheckSupportedBrowser",
    component: _9719eefa,
    name: "sandbox-Helpers-CheckSupportedBrowser"
  }, {
    path: "/sandbox/Helpers/Config",
    component: _14bb27a7,
    name: "sandbox-Helpers-Config"
  }, {
    path: "/sandbox/Helpers/GetDirectoriesRandomFiles",
    component: _a883a2f4,
    name: "sandbox-Helpers-GetDirectoriesRandomFiles"
  }, {
    path: "/sandbox/Helpers/GetFirstImageDimension",
    component: _ef63c3b8,
    name: "sandbox-Helpers-GetFirstImageDimension"
  }, {
    path: "/sandbox/Helpers/GetImageFromFile",
    component: _3dde8e84,
    name: "sandbox-Helpers-GetImageFromFile"
  }, {
    path: "/sandbox/Helpers/ReadFileSync",
    component: _4578e8c8,
    name: "sandbox-Helpers-ReadFileSync"
  }, {
    path: "/sandbox/Helpers/UploadFiles",
    component: _71d0384f,
    name: "sandbox-Helpers-UploadFiles"
  }, {
    path: "/admin/artists/:id",
    component: _0f165a96,
    name: "admin-artists-id"
  }, {
    path: "/admin/projects/:id",
    component: _7032fefc,
    name: "admin-projects-id"
  }, {
    path: "/blog/:slug",
    component: _3787fcb1,
    name: "blog-slug"
  }, {
    path: "/collections/:id",
    component: _1c77e21f,
    name: "collections-id"
  }, {
    path: "/help/:category",
    component: _2e857426,
    name: "help-category"
  }, {
    path: "/profile/:profileId",
    component: _ed20dd3c,
    name: "profile-profileId"
  }, {
    path: "/collections/:id/purchase",
    component: _643e7744,
    name: "collections-id-purchase"
  }, {
    path: "/collections/:id/widget",
    component: _364c81c7,
    name: "collections-id-widget"
  }, {
    path: "/profile/:profileId?/collections",
    component: _61169168,
    name: "profile-profileId-collections"
  }, {
    path: "/profile/:profileId?/vault",
    component: _597d6ac2,
    name: "profile-profileId-vault"
  }, {
    path: "/assets/:collectionId?/:assetId",
    component: _27c2430e,
    name: "assets-collectionId-assetId"
  }, {
    path: "/c/:id?/:wallet?",
    component: _3ab1fc1a,
    name: "c-id-wallet"
  }, {
    path: "/",
    component: _319859a4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
