//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Appbar from '@/components/admin/layouts/Appbar'
import HomeView from '@/components/layout/Homeview'
import SideBar from '@/components/admin/layouts/Sidebar'

export default {
  name: 'AdminLayout',
  components: {
    Appbar,
    HomeView,
    SideBar,
  },
  computed: {
    ...mapState(['snackbars']),
  },
}
