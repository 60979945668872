//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    btnPlain: Boolean,
    btnLabel: {
      type: String,
      default: 'Log in'
    },
    btnUrl: {
      type: String,
      default: '/'
    },
    btnColor: {
      type: String,
      default: ''
    },
    btnRounded: {
      type: Boolean,
      default: false
    },
    btnOutlined: {
      type: Boolean,
      default: false
    },
    btnLarge: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: Boolean,
      default: false
    },
    btnHidden: {
      type: Boolean,
      default: true
    },
    customClass: {
      type:String,
      default:''
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    snackbar: false,
    tab: 0,
    tabs: [
      {name:"Login", icon:"mdi-account"},
      {name:"Register", icon:"mdi-account-outline"}
    ],
    loginFormValid: false,
    loginData: {
      liUsername: '',
      liPassword: ''
    },
    loginValidation: [],
    signupFormValid: false,
    signupData: {
      firstname: '',
      lastname: '',
      username: '',
      email:'',
      password: '',
      verify: ''
    },
    suShow1: false,
    suShow2: false,
    liShow: false,
    isRegistred : false,
    usernameValidation: [],
    emailValidation: [],
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => (`The email and password you entered don't match`),
    }
  }),
  computed: {
    passwordMatch() {
        return () => this.signupData.password === this.signupData.verify || "Password must match";
    }
  },
  methods: {
    openDialog() {
      this.dialog = true
    },
    async register() {
      try {
        this.loading = true
        const user = await this.$axios.$post("/api/users", {
          firstname: this.signupData.firstname,
          lastname: this.signupData.lastname,
          username: this.signupData.username,
          email: this.signupData.email,
          password: this.signupData.password,
          verify: this.signupData.verify
        })
        if(user){
          this.isRegistred = true;
          this.tab = 0;
        }
        this.loading = false
        this.dialog = false
        this.$router.push(this.btnUrl)
      } catch (err) {
        this.usernameValidation = err.response.data.errors.filter(error => {
          return error.param === 'username'
        }).map(item => {return item.msg})
        this.emailValidation = err.response.data.errors.filter(error => {
          return error.param === 'email'
        }).map(item => {return item.msg})
        this.loading = false
      }
    },
    async login() {
      try {
        this.loading = true
        const resp = await this.$auth.loginWith('local', {
          data: {
            username: this.loginData.username,
            password: this.loginData.password
          }
        })

        const user = this.$store.getters.getUserInfo

        this.$store.commit('SET_TOKEN', resp.data.token.toString()) // save token in state
        this.$store.commit('SET_ROLE', user.role)

        if (user.role !== 'admin') {
          this.$router.push('/')
        } else {
          this.$router.push('/admin/dashboard')
        }

        this.loading = false
        this.dialog = false
      } catch (e) {
        const msg = 'Username and/or password is not valid.'
        this.loginValidation = e.response.data.message ?
        msg : ''
        this.loading = false
      }
    }
  },
}
