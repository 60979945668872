export const state = () => ({
  usersList: {},
  usersByRoleList: {},
  user: {}
})

export const mutations = {
  SET_USERS_LIST(state, data) {
    state.usersList = data
  },
  SET_USERS_BY_ROLE_LIST(state, data) {
    state.usersByRoleList = data
  },
  SET_USER_BY_ID(state, data) {
    state.user = data
  },
}

export const actions = {
  async loadUsersList({commit}) {
    return await this.$axios.$get('/api/users')
    .then(res => {
      return res
    })
    .then(res => {
      commit ('SET_USERS_LIST', res.users)

      return {
        success: true,
        data: res.users,
      }
    })
    .catch(err => {
      return {
        success: false,
        err
      }
    })
  },

  async loadUsersByRole({commit}, role) {
    return await this.$axios.$get(`/api/users?role=${role}`)
    .then(res => {
      return res
    })
    .then(res => {
      commit ('SET_USER_BY_ID', res)

      return {
        success: true,
        data: res,
      }
    })
    .catch(err => {
      return {
        success: false,
        err
      }
    })
  },

  async loadUsersById({commit}, id) {
    return await this.$axios.$get(`/api/users/${id}`)
    .then(res => {
      return res
    })
    .then(res => {
      commit ('SET_USER_BY_ID', res)

      return {
        success: true,
        data: res,
      }
    })
    .catch(err => {
      return {
        success: false,
        err
      }
    })
  },
}

export const getters = {
  usersListItems(state) {
    return state.usersList
  },
  usersByRoleListItems(state) {
    return state.usersByRoleList
  },
  usersById(state) {
    return state.user
  },
}
