import colors from 'vuetify/es5/util/colors'
import Menu from '~/components/icons/Menu.vue'
import Profile from '~/components/icons/Profile.vue'

export default {
  theme: {
    options: { customProperties: true },
    dark: true,
    themes: {
      dark: {
        background: '#000000',
        primary: colors.blue.darken2,
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
      },
    },
  },
  icons: {
    values: {
      menu: {
        component: Menu
      },
      profile: {
        component: Profile
      },
    },
  },
}