//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    
  },
  data: () => ({
    dark: false,
    items: [
      { title: 'My Profile' },
      { title: 'Log out' },
    ],
  }),
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;  // it check if user isAuthenticated 
    },
    getUserInfo() {
      return this.$store.getters.getUserInfo;
    }
  },
  methods: {
    async logout() {
      await this.$auth.logout();  // this method will logout the user and make token to false on the local storage of the user browser
    },
    darkMode() {
      this.dark = true
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  },
}
