//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'HomeView',
  props: {
    userAlias: {
      type: String,
      default: null,
    },
    userAddress: {
      type: String,
      default: null,
    },
    balance: {
      type: Number,
      default: null,
    }
  },
  methods: {
    disconnectWallet() {
      this.$emit('disconnectWallet')
    },
    loadTopUpModule() {
      this.$emit('loadTopUpModule')
    },
    connectWallet() {
      this.$emit('connectWallet')
    }
  }
}
