// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/ClashDisplay-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/ClashDisplay-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/ClashDisplay-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/ClashDisplay-Semibold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page-enter-active,.page-leave-active{transition:opacity .2s}.page-enter,.page-leave-to{opacity:0}.layout-enter-active,.layout-leave-active{transition:opacity .2s}.layout-enter,.layout-leave-active{opacity:0}.my-page-enter-active,.my-page-leave-active{transition:opacity .2s}.my-page-enter,.my-page-leave-to{opacity:0}@font-face{font-family:\"ClashDisplay-Regular\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:\"ClashDisplay-Medium\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}@font-face{font-family:\"ClashDisplay-Bold\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}@font-face{font-family:\"ClashDisplay-SemiBold\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\")}html{font-family:Inter}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
