export const state = () => ({
  project: {},
  projectList: [],
  projectsByUserIdList: [],
  projectTokens: [],
  featuredTokens: [],
  featuredToken: [],
})

export const mutations = {
  SET_PROJECT(state, data) {
    state.project = data
  },
  SET_PROJECT_STATUS(state, data) {
    state.project.status = data
  },
  SET_PROJECTS_LIST(state, data) {
    state.projectList = data
  },
  SET_PROJECTS_LIST_BY_USER_ID(state, data) {
    state.projectsByUserId = data
  },
  SET_PROJECT_TOKENS(state, data) {
    state.projectTokens = data
  },
  SET_FEATURED_PROJECT_TOKENS(state, data) {
    state.featuredTokens = data
  },
  SET_FEATURED_PROJECT_TOKEN(state, data) {
    state.featuredToken = data
  },
}

export const actions = {
  async loadProject({ commit }, id) {
    return await this.$axios
      .$get(`/api/projects/${id}?includeLayers=true&includeUser=true`)
      .then((res) => {
        return res
      })
      .then((res) => {
        commit('SET_PROJECT', res)

        return {
          success: true,
          data: res,
        }
      })
      .catch((err) => {
        return {
          success: false,
          err,
        }
      })
  },

  async loadProjectList({ commit }) {
    return await this.$axios
      .$get('/api/projects')
      .then((res) => {
        return res
      })
      .then((res) => {
        commit('SET_PROJECTS_LIST', res.projects)

        return {
          success: true,
          data: res.projects,
        }
      })
      .catch((err) => {
        return {
          success: false,
          err,
        }
      })
  },

  async loadProjectsByUserId({ commit }, id) {
    return await this.$axios
      .$get(`/api/users/${id}/projects`)
      .then((res) => {
        return res
      })
      .then((res) => {
        commit('SET_PROJECTS_LIST_BY_USER_ID', res)

        return {
          success: true,
          data: res,
        }
      })
      .catch((err) => {
        return {
          success: false,
          err,
        }
      })
  },

  async loadProjectTokens({ commit }, { id, limit = 10, offset = 0 }) {
    try {
      const request = {
        method: 'GET',
        url: `/api/projects/${id}/sampletokens`,
        params: {
          limit,
          offset,
        },
      }
      // eslint-disable-next-line no-useless-catch
      const res = await this.$axios(request)
      commit('SET_PROJECT_TOKENS', res.data.tokens)

      return {
        success: true,
        data: res.data,
      }
    } catch (err) {
      return {
        success: false,
        err,
      }
    }
  },

  async loadProjectFeaturedTokens({ commit }, id) {
    return await this.$axios
      .$get(`/api/projects/${id}/sampletokens?type=curated&limit=9`)
      .then((res) => {
        return res
      })
      .then((res) => {
        commit('SET_FEATURED_PROJECT_TOKENS', res)

        return {
          success: true,
          data: res.tokens,
        }
      })
      .catch((err) => {
        return {
          success: false,
          err,
        }
      })
  },

  async loadProjectFeaturedToken({ commit }, id) {
    return await this.$axios
      .$get(`/api/projects/${id}/sampletokens?type=featured&limit=1`)
      .then((res) => {
        return res
      })
      .then((res) => {
        commit('SET_FEATURED_PROJECT_TOKENS', res)

        return {
          success: true,
          data: res.tokens,
        }
      })
      .catch((err) => {
        return {
          success: false,
          err,
        }
      })
  },
}

export const getters = {
  projectItem(state) {
    return state.project
  },

  projectListItems(state) {
    return state.projectList
  },

  projectsByUserIdListItems(state) {
    return state.projectsByUserIdList
  },

  projectTokens(state) {
    return state.projectTokens
  },

  projectFeaturedTokens(state) {
    return state.featuredTokens
  },

  projectFeaturedToken(state) {
    return state.featuredToken
  },
}
