//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: String,
      default: '',
    },
    connectWallet: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
      agree: false,
    }
  },
  watch: {
    dialog(v) {
      if (v) {
        this.agree = false
      }
    }
  },
  methods: {
    handleOpen() {
      this.dialog = true
    },
    openHelpWallet() {
      this.dialog = false
      this.$refs.helpWalletDialog.handleOpen()
    }
  },
}
