//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import CreateDialog from '@/components/CreateDialog'
export default {
  components: {
    CreateDialog,
  },
  data() {
    return {
      newletterSignup: '',
      loading: false,
      pages: [
        'collections',
        'collections-id',
        'profile-profileId',
        'profile-profileId-collections',
        'profile-profileId-vault',
        'assets-collectionId-assetId',
        'blog',
        'blog-slug',
        'sandbox',
        'create-id',
        'artists'
      ]
    }
  },
  computed: {
    light() {
      return !this.pages.includes(this.$nuxt.$route.name)
    },
    color() {
      return this.pages.includes(this.$nuxt.$route.name) ? '#1E1E1E' : 'white'
    },
    year() {
      return moment().year()
    },
  },
  methods: {
    handleOpen() {
      this.$refs.createDialog.handleOpen()
    },
    async signup() {
      try {
        this.loading = true
        // console.log(this.newletterSignup)
        await this.$axios.$post('/api/newsletter', {
          email: this.newletterSignup,
        })
        this.loading = false
        this.newletterSignup = ''
        this.$store.dispatch('addSnackbar', { text: `You have subscribed!` })
      } catch (err) {
        this.loading = false
        this.$store.dispatch('addSnackbar', {
          text: `Error subscribing, please check your email address and try again.`,
          type: 'error',
        })
      }
    },
  },
}
