//
//
//
//
//
//


export default {
  name: 'HomeView',
}
