//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FourOhFour',
  provide: {
    heading: { align: 'center' },
  },
  layout: 'admin-landing',
}
