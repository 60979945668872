import {SigningType} from "@airgap/beacon-sdk";
import { b58cencode } from "@taquito/utils";

const globals = {
  tezos: null,
  wallet: null,
  tzDomains: null,
  async connectWallet(rpc = null) {
    if (this.tezos === null || this.wallet === null) {
      throw new Error('Must set up tezos first')
    }
    const tezosRpc =
      this.config.tezosRpcs[
        Math.floor(this.config.tezosRpcs.length * Math.random())
      ]
    await this.wallet.requestPermissions({
      network: {
        type: this.config.tezosNetwork,
        rpcUrl: rpc || tezosRpc,
      },
    })
    const userAddress = await this.wallet.getPKH()

    this.tezos.setProvider({ wallet: this.wallet })

    return userAddress
  },
  async generateSignedMessage() {
    const activeAccount =  await this.wallet.client.getActiveAccount() ;

    if (!activeAccount) {
      throw new Error("Could not fetch active account");
    }

    if (activeAccount.publicKey.length > 55) {
      activeAccount.publicKey = b58cencode(
        activeAccount.publicKey,
        new Uint8Array([13, 15, 37, 217])
      );
    }

    const dappName = "artforge.io";
    const ISO8601formatedTimestamp = new Date().toISOString();

    // The full string
    const formattedInput = [
      "Tezos Signed Message: Confirming identity on",
      dappName,
      "as",
      await this.wallet.getPKH(),
      "at",
      ISO8601formatedTimestamp,
    ].join(" ");

    const payloadMessageHex = this.getMessageHex(formattedInput);

    // The payload to send to the wallet
    const payload = {
      signingType: SigningType.MICHELINE,
      payload: payloadMessageHex,
    };

    // The signing
    const signedPayload = await this.wallet.client.requestSignPayload(payload);

    // The signature
    return {
      signature: signedPayload.signature,
      pk: activeAccount.publicKey,
      message: payloadMessageHex
    };
  },
  async disconnectWallet() {
    if (this.wallet === null) {
      return
    }

    await this.wallet.client.disconnect()
  },
  async resolveAddressToName(address) {
    if (this.tzDomains === null) {
      return
    }
    return await this.tzDomains.resolver.resolveAddressToName(address)
  },
  async resolveNameToAddress(name) {
    if (this.tzDomains === null) {
      return
    }
    return await this.tzDomains.resolver.resolveNameToAddress(name)
  },
  getMessageHex(message) {
    const input = Buffer.from(message)
    const prefix = Buffer.from('0501', 'hex')
    const lenBytes = Buffer.from(
      message.length.toString(16).padStart(8, '0'),
      'hex'
    )
    const value = Buffer.concat(
      [prefix, lenBytes, input],
      prefix.length + lenBytes.length + input.length
    )
    return value.toString('hex')
  }
}

const configGlobal = (app) => {
  globals.config = app.$config
  return globals
}

export default ({ app }, inject) => {
  inject('globals', configGlobal(app))
}
