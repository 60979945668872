//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WertWidget from '@wert-io/widget-initializer'
import { v4 as uuidv4 } from 'uuid'
export default {
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: String,
      default: '',
    },
    finished: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      monitorLoop: false,
      curBalance: null,
      loading: false,
    }
  },
  computed: {
    userAddress() {
      return this.$store.getters.userAddress
    },
    balance() {
      return this.$store.getters.balance
    },
    wertWidgetId() {
      return `wertWidget${uuidv4()}`
    }
  },
  watch: {
    balance(nb) {
      if (nb !== this.curBalance && this.dialog) {
        console.log('new balance', nb)
        this.monitorBalance = false
        if (this.finished) {
          this.finished()
        } else {
          this.$store.dispatch('addSnackbar', {
            text: `Your XTZ (Tezos) purchase was successful. You may now use your XTZ (Tezos) balance.`,
          })
        }
        this.dialog = false
      }
    },
  },
  methods: {
    async handleOpen(amount, bal) {
      this.dialog = true
      this.curBalance = bal
      await new Promise((resolve) => setTimeout(resolve, 250))
      const { wertOrigin, wertPartnerId } = this.$config
      const wertOptions = {
        partner_id: wertPartnerId,
        container_id: this.wertWidgetId,
        click_id: uuidv4(), // unique id of purchase in your system
        height: 575,
        currency: 'USD',
        commodity: 'XTZ',
        commodities: 'XTZ',
        origin: wertOrigin,
        address: this.userAddress,
      }
      if (amount) {
        wertOptions.commodity_amount = amount
      }
      const wertWidget = new WertWidget(wertOptions)
      wertWidget.mount()
      this.monitorLoop = true
      this.monitorBalance()
    },
    async monitorBalance() {
      if (this.monitorLoop && this.dialog) {
        this.loading = true
        this.$store.dispatch('loadBalance', this.userAddress)
        await new Promise((resolve) => setTimeout(resolve, 1000))
        this.loading = false
        await new Promise((resolve) => setTimeout(resolve, 4000))
        this.monitorBalance()
      }
    },
  },
}
