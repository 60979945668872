//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TopUpPopUp from '../TopUpPopUp.vue'
import WalletDialog from '@/components/WalletDialog.vue'
import CreateDialog from '@/components/CreateDialog'
import HelpWalletDialog from '@/components/HelpWalletDialog'
import SearchBar from '@/components/SearchBar'
import ConnectedUserDialog from '~/components/layout/ConnectedUserDialog'

export default {
  name: 'HomeAppBar',

  components: {
    CreateDialog,
    HelpWalletDialog,
    WalletDialog,
    TopUpPopUp,
    SearchBar,
    ConnectedUserDialog,
  },

  data: () => ({
    drawer: null,
    items: [
      {
        name: 'Explore',
        url: '/collections',
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/ArtForge_io',
      },
      {
        name: 'Instagram',
        url: 'https://instagram.com/artforge_nft',
      },
      {
        name: 'Telegram',
        url: 'https://t.me/artforge_io',
      },
      {
        name: 'Discord',
        url: 'https://discord.gg/qjwm2AEVMf',
      },
    ],
    dropdown: [
      ['internal', 'Blog', '/blog'],
      ['external', 'Twitter', 'https://twitter.com/ArtForge_io'],
      ['external', 'Instagram', 'https://instagram.com/artforge_nft'],
      // ['external', 'Telegram', 'https://t.me/artforge_io'],
      ['external', 'Discord', 'https://discord.gg/qjwm2AEVMf'],
    ],
    mouseover: false,
    bal: 0,
  }),
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated // it check if user isAuthenticated
    },
    getUserInfo() {
      return this.$store.getters.getUserInfo
    },
    userAddress() {
      if (this.$auth.user) return this.$auth.user.wallet

      return null
    },
    userAvatar() {
      if (this.$auth.user) return this.$auth.user.avatar

      return ''
    },
    userAlias() {
      if (this.$auth.user) return this.$auth.user.username

      return ''
    },
    formattedAddr() {
      if (!this.$auth.user) return ''

      if (this.$auth.user?.username) {
        if (this.$auth.user?.username.length > 20) {
          return (
            this.$auth.user?.username.substring(0, 6) +
            '...' +
            this.$auth.user?.username.substring(
              this.$auth.user?.username.length - 6
            )
          )
        }
        return this.$auth.user?.username
      }

      return (
        this.$auth.user?.wallet.substring(0, 6) +
        '...' +
        this.$auth.user?.wallet.substring(this.$auth.user?.wallet.length - 6)
      )
    },
    tezosLoaded() {
      return this.$globals.tezos !== null
    },
    balance() {
      return this.$store.getters.balance
    },
    showTopUpPopUp() {
      return this.$store.state.prefs.showTopUpPopUp
    },
  },

  methods: {
    loadTopUpModule() {
      this.$refs.topUpDialog.handleOpen(null, this.balance)
    },
    handleOpen() {
      this.$refs.createDialog.handleOpen()
    },
    handleOpenHelpWallet() {
      this.$refs.createDialog.handleOpen()
    },
    handleOpenWallet() {
      this.$refs.walletDialog.handleOpen()
    },
    toggleMouseover(e) {
      this.mouseover = !this.mouseover
    },

    async logout() {
      await this.$auth.logout() // this method will logout the user and make token to false on the local storage of the user browser
    },
    async connectWallet() {
      try {
        const userAddress = await this.$globals.connectWallet()

        const data = await this.$globals.generateSignedMessage()

        const userAlias = await this.$globals.resolveAddressToName(userAddress)

        data.username = userAlias

        try {
          const resp = await this.$auth.loginWith('wallet', {
            data,
          })

          this.$store.commit('SET_TOKEN', resp.data.token.toString()) // save token in state

          const user = this.$store.getters.getUserInfo
          this.$store.commit('SET_ROLE', user.role)
        } catch (e) {
          const msg = 'Username and/or password is not valid.'
          console.log(msg)
        }

        // add signed message auth flow

        this.$store.commit('INIT_USER_WALLET', {
          wallet: userAddress,
          alias: userAlias,
        })
        this.$store.dispatch('logTerms', {
          wallet: userAddress,
          version: this.$config.termsVersion,
        })
        await this.$store.dispatch('loadBalance', userAddress)
        if (this.balance < 10000000 && this.showTopUpPopUp) {
          this.$refs.topUpPopUp.handleOpen()
        }
      } catch (e) {
        console.error('Unable to connect wallet', e)
      }
    },
    async disconnectWallet() {
      await this.$globals.disconnectWallet()
      this.$store.commit('INIT_USER_WALLET', { wallet: null, alias: null })
      await this.logout()
    },
  },
}
