export const state = () => ({})

export const getters = {}

export const mutations = {}

export const actions = {
  async getProfileProjects(context, payload) {
    const res = await this.$axios({
      method: 'GET',
      url: '/api/projects/profile-projects',
      params: {
        profile: payload.profile
      },
      headers: {
        Authorization: `Bearer ${payload.token}`
      }
    })
    return res.data
  },
  async newFromModel(context, payload) {
    const res = await this.$axios({
      method: 'GET',
      url: '/api/projects/new-from-model',
      headers: {
        Authorization: `Bearer ${payload.token}`
      }
    })
    return res.data
  },
  async delete(context, payload) {
    await this.$axios({
      method: 'DELETE',
      url: `/api/projects/${payload.projectId}`,
    })
  },
}
