export default function ({ store, redirect }) {
  // If the user is not authenticated
  if (!store.getters.isAuthenticated) {
    return redirect('/')
  }

  // else {
  //   const user = store.getters.getUserInfo
  //   if (user.role === 'admin') {
  //     return redirect('/admin/dashboard')
  //   } else {
  //     return redirect('/')
  //   }
  // }
}
