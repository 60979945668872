export const AffiliatesTab = () => import('../../components/AffiliatesTab.vue' /* webpackChunkName: "components/affiliates-tab" */).then(c => wrapFunctional(c.default || c))
export const AffiliatesTabDialog = () => import('../../components/AffiliatesTabDialog.vue' /* webpackChunkName: "components/affiliates-tab-dialog" */).then(c => wrapFunctional(c.default || c))
export const ArtistCollection = () => import('../../components/ArtistCollection.vue' /* webpackChunkName: "components/artist-collection" */).then(c => wrapFunctional(c.default || c))
export const ArtistDialog = () => import('../../components/ArtistDialog.vue' /* webpackChunkName: "components/artist-dialog" */).then(c => wrapFunctional(c.default || c))
export const ArtistHeader = () => import('../../components/ArtistHeader.vue' /* webpackChunkName: "components/artist-header" */).then(c => wrapFunctional(c.default || c))
export const Collections = () => import('../../components/Collections.vue' /* webpackChunkName: "components/collections" */).then(c => wrapFunctional(c.default || c))
export const Confirmation = () => import('../../components/Confirmation.vue' /* webpackChunkName: "components/confirmation" */).then(c => wrapFunctional(c.default || c))
export const CreateDialog = () => import('../../components/CreateDialog.vue' /* webpackChunkName: "components/create-dialog" */).then(c => wrapFunctional(c.default || c))
export const CreatorTab = () => import('../../components/CreatorTab.vue' /* webpackChunkName: "components/creator-tab" */).then(c => wrapFunctional(c.default || c))
export const Crest = () => import('../../components/Crest.vue' /* webpackChunkName: "components/crest" */).then(c => wrapFunctional(c.default || c))
export const CuratedCard = () => import('../../components/CuratedCard.vue' /* webpackChunkName: "components/curated-card" */).then(c => wrapFunctional(c.default || c))
export const CuratedProjectCard = () => import('../../components/CuratedProjectCard.vue' /* webpackChunkName: "components/curated-project-card" */).then(c => wrapFunctional(c.default || c))
export const CuratedProjects = () => import('../../components/CuratedProjects.vue' /* webpackChunkName: "components/curated-projects" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const FeaturedCountdown = () => import('../../components/FeaturedCountdown.vue' /* webpackChunkName: "components/featured-countdown" */).then(c => wrapFunctional(c.default || c))
export const FeaturedCreatorCard = () => import('../../components/FeaturedCreatorCard.vue' /* webpackChunkName: "components/featured-creator-card" */).then(c => wrapFunctional(c.default || c))
export const FeaturedCreators = () => import('../../components/FeaturedCreators.vue' /* webpackChunkName: "components/featured-creators" */).then(c => wrapFunctional(c.default || c))
export const FeaturedProject = () => import('../../components/FeaturedProject.vue' /* webpackChunkName: "components/featured-project" */).then(c => wrapFunctional(c.default || c))
export const FeaturedSlider = () => import('../../components/FeaturedSlider.vue' /* webpackChunkName: "components/featured-slider" */).then(c => wrapFunctional(c.default || c))
export const ForgeImage = () => import('../../components/ForgeImage.vue' /* webpackChunkName: "components/forge-image" */).then(c => wrapFunctional(c.default || c))
export const HelpCreateGenerativeNft = () => import('../../components/HelpCreateGenerativeNft.vue' /* webpackChunkName: "components/help-create-generative-nft" */).then(c => wrapFunctional(c.default || c))
export const HelpWalletDialog = () => import('../../components/HelpWalletDialog.vue' /* webpackChunkName: "components/help-wallet-dialog" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const HowItWorks = () => import('../../components/HowItWorks.vue' /* webpackChunkName: "components/how-it-works" */).then(c => wrapFunctional(c.default || c))
export const HowToParticipate = () => import('../../components/HowToParticipate.vue' /* webpackChunkName: "components/how-to-participate" */).then(c => wrapFunctional(c.default || c))
export const Instagram = () => import('../../components/Instagram.vue' /* webpackChunkName: "components/instagram" */).then(c => wrapFunctional(c.default || c))
export const IpfsCard = () => import('../../components/IpfsCard.vue' /* webpackChunkName: "components/ipfs-card" */).then(c => wrapFunctional(c.default || c))
export const LandingFooter = () => import('../../components/LandingFooter.vue' /* webpackChunkName: "components/landing-footer" */).then(c => wrapFunctional(c.default || c))
export const LandpingProjects = () => import('../../components/LandpingProjects.vue' /* webpackChunkName: "components/landping-projects" */).then(c => wrapFunctional(c.default || c))
export const LoginSignup = () => import('../../components/LoginSignup.vue' /* webpackChunkName: "components/login-signup" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceTab = () => import('../../components/MarketplaceTab.vue' /* webpackChunkName: "components/marketplace-tab" */).then(c => wrapFunctional(c.default || c))
export const MyProjects = () => import('../../components/MyProjects.vue' /* webpackChunkName: "components/my-projects" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const ProjectCard = () => import('../../components/ProjectCard.vue' /* webpackChunkName: "components/project-card" */).then(c => wrapFunctional(c.default || c))
export const ProjectCollection = () => import('../../components/ProjectCollection.vue' /* webpackChunkName: "components/project-collection" */).then(c => wrapFunctional(c.default || c))
export const ProjectDetail = () => import('../../components/ProjectDetail.vue' /* webpackChunkName: "components/project-detail" */).then(c => wrapFunctional(c.default || c))
export const ProjectTokenCard = () => import('../../components/ProjectTokenCard.vue' /* webpackChunkName: "components/project-token-card" */).then(c => wrapFunctional(c.default || c))
export const Projects = () => import('../../components/Projects.vue' /* webpackChunkName: "components/projects" */).then(c => wrapFunctional(c.default || c))
export const PublicProject = () => import('../../components/PublicProject.vue' /* webpackChunkName: "components/public-project" */).then(c => wrapFunctional(c.default || c))
export const PurchaseTokenDialog = () => import('../../components/PurchaseTokenDialog.vue' /* webpackChunkName: "components/purchase-token-dialog" */).then(c => wrapFunctional(c.default || c))
export const Royalties = () => import('../../components/Royalties.vue' /* webpackChunkName: "components/royalties" */).then(c => wrapFunctional(c.default || c))
export const SampleArtwork = () => import('../../components/SampleArtwork.vue' /* webpackChunkName: "components/sample-artwork" */).then(c => wrapFunctional(c.default || c))
export const SampleArtworkDialog = () => import('../../components/SampleArtworkDialog.vue' /* webpackChunkName: "components/sample-artwork-dialog" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SelectImages = () => import('../../components/SelectImages.vue' /* webpackChunkName: "components/select-images" */).then(c => wrapFunctional(c.default || c))
export const SellTokensDialog = () => import('../../components/SellTokensDialog.vue' /* webpackChunkName: "components/sell-tokens-dialog" */).then(c => wrapFunctional(c.default || c))
export const StartCountdown = () => import('../../components/StartCountdown.vue' /* webpackChunkName: "components/start-countdown" */).then(c => wrapFunctional(c.default || c))
export const TermsCondition = () => import('../../components/TermsCondition.vue' /* webpackChunkName: "components/terms-condition" */).then(c => wrapFunctional(c.default || c))
export const ToggleTheme = () => import('../../components/ToggleTheme.vue' /* webpackChunkName: "components/toggle-theme" */).then(c => wrapFunctional(c.default || c))
export const TokenCollection = () => import('../../components/TokenCollection.vue' /* webpackChunkName: "components/token-collection" */).then(c => wrapFunctional(c.default || c))
export const TopUpDialog = () => import('../../components/TopUpDialog.vue' /* webpackChunkName: "components/top-up-dialog" */).then(c => wrapFunctional(c.default || c))
export const TopUpPopUp = () => import('../../components/TopUpPopUp.vue' /* webpackChunkName: "components/top-up-pop-up" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const Upload = () => import('../../components/Upload.vue' /* webpackChunkName: "components/upload" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../components/UserMenu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const VideoBackgroundSSR = () => import('../../components/VideoBackgroundSSR.vue' /* webpackChunkName: "components/video-background-s-s-r" */).then(c => wrapFunctional(c.default || c))
export const WalletDialog = () => import('../../components/WalletDialog.vue' /* webpackChunkName: "components/wallet-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminDataIterator = () => import('../../components/admin/DataIterator.vue' /* webpackChunkName: "components/admin-data-iterator" */).then(c => wrapFunctional(c.default || c))
export const AdminDeleteTraitDialog = () => import('../../components/admin/DeleteTraitDialog.vue' /* webpackChunkName: "components/admin-delete-trait-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminProjectIterator = () => import('../../components/admin/ProjectIterator.vue' /* webpackChunkName: "components/admin-project-iterator" */).then(c => wrapFunctional(c.default || c))
export const AdminProjects = () => import('../../components/admin/Projects.vue' /* webpackChunkName: "components/admin-projects" */).then(c => wrapFunctional(c.default || c))
export const AdminUserIterator = () => import('../../components/admin/UserIterator.vue' /* webpackChunkName: "components/admin-user-iterator" */).then(c => wrapFunctional(c.default || c))
export const AdminUserRegistrations = () => import('../../components/admin/UserRegistrations.vue' /* webpackChunkName: "components/admin-user-registrations" */).then(c => wrapFunctional(c.default || c))
export const BaseAvatar = () => import('../../components/base/Avatar.vue' /* webpackChunkName: "components/base-avatar" */).then(c => wrapFunctional(c.default || c))
export const BaseAvatarCard = () => import('../../components/base/AvatarCard.vue' /* webpackChunkName: "components/base-avatar-card" */).then(c => wrapFunctional(c.default || c))
export const BaseBody = () => import('../../components/base/Body.vue' /* webpackChunkName: "components/base-body" */).then(c => wrapFunctional(c.default || c))
export const BaseBtn = () => import('../../components/base/Btn.vue' /* webpackChunkName: "components/base-btn" */).then(c => wrapFunctional(c.default || c))
export const BaseBusinessContact = () => import('../../components/base/BusinessContact.vue' /* webpackChunkName: "components/base-business-contact" */).then(c => wrapFunctional(c.default || c))
export const BaseBusinessInfo = () => import('../../components/base/BusinessInfo.vue' /* webpackChunkName: "components/base-business-info" */).then(c => wrapFunctional(c.default || c))
export const BaseCircularProgress = () => import('../../components/base/CircularProgress.vue' /* webpackChunkName: "components/base-circular-progress" */).then(c => wrapFunctional(c.default || c))
export const BaseContactForm = () => import('../../components/base/ContactForm.vue' /* webpackChunkName: "components/base-contact-form" */).then(c => wrapFunctional(c.default || c))
export const BaseDivider = () => import('../../components/base/Divider.vue' /* webpackChunkName: "components/base-divider" */).then(c => wrapFunctional(c.default || c))
export const BaseGalleryCard = () => import('../../components/base/GalleryCard.vue' /* webpackChunkName: "components/base-gallery-card" */).then(c => wrapFunctional(c.default || c))
export const BaseHeading = () => import('../../components/base/Heading.vue' /* webpackChunkName: "components/base-heading" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/base/Icon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseImg = () => import('../../components/base/Img.vue' /* webpackChunkName: "components/base-img" */).then(c => wrapFunctional(c.default || c))
export const BaseInfo = () => import('../../components/base/Info.vue' /* webpackChunkName: "components/base-info" */).then(c => wrapFunctional(c.default || c))
export const BaseInfoCard = () => import('../../components/base/InfoCard.vue' /* webpackChunkName: "components/base-info-card" */).then(c => wrapFunctional(c.default || c))
export const BaseListItem = () => import('../../components/base/ListItem.vue' /* webpackChunkName: "components/base-list-item" */).then(c => wrapFunctional(c.default || c))
export const BaseProgress = () => import('../../components/base/Progress.vue' /* webpackChunkName: "components/base-progress" */).then(c => wrapFunctional(c.default || c))
export const BaseSection = () => import('../../components/base/Section.vue' /* webpackChunkName: "components/base-section" */).then(c => wrapFunctional(c.default || c))
export const BaseSectionHeading = () => import('../../components/base/SectionHeading.vue' /* webpackChunkName: "components/base-section-heading" */).then(c => wrapFunctional(c.default || c))
export const BaseSubheading = () => import('../../components/base/Subheading.vue' /* webpackChunkName: "components/base-subheading" */).then(c => wrapFunctional(c.default || c))
export const BaseSubtitle = () => import('../../components/base/Subtitle.vue' /* webpackChunkName: "components/base-subtitle" */).then(c => wrapFunctional(c.default || c))
export const BaseTextField = () => import('../../components/base/TextField.vue' /* webpackChunkName: "components/base-text-field" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/base/Textarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const BaseTitle = () => import('../../components/base/Title.vue' /* webpackChunkName: "components/base-title" */).then(c => wrapFunctional(c.default || c))
export const BlogPrevNextBlog = () => import('../../components/blog/PrevNextBlog.vue' /* webpackChunkName: "components/blog-prev-next-blog" */).then(c => wrapFunctional(c.default || c))
export const BlogReadTime = () => import('../../components/blog/ReadTime.vue' /* webpackChunkName: "components/blog-read-time" */).then(c => wrapFunctional(c.default || c))
export const IconsMenu = () => import('../../components/icons/Menu.vue' /* webpackChunkName: "components/icons-menu" */).then(c => wrapFunctional(c.default || c))
export const IconsProfile = () => import('../../components/icons/Profile.vue' /* webpackChunkName: "components/icons-profile" */).then(c => wrapFunctional(c.default || c))
export const LayoutAppbar = () => import('../../components/layout/Appbar.vue' /* webpackChunkName: "components/layout-appbar" */).then(c => wrapFunctional(c.default || c))
export const LayoutConnectedUserDialog = () => import('../../components/layout/ConnectedUserDialog.vue' /* webpackChunkName: "components/layout-connected-user-dialog" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHomeview = () => import('../../components/layout/Homeview.vue' /* webpackChunkName: "components/layout-homeview" */).then(c => wrapFunctional(c.default || c))
export const LayoutLanding = () => import('../../components/layout/Landing.vue' /* webpackChunkName: "components/layout-landing" */).then(c => wrapFunctional(c.default || c))
export const ProjectsCheckBrowser = () => import('../../components/projects/CheckBrowser.vue' /* webpackChunkName: "components/projects-check-browser" */).then(c => wrapFunctional(c.default || c))
export const ProjectsCrowdsaleWidget = () => import('../../components/projects/CrowdsaleWidget.vue' /* webpackChunkName: "components/projects-crowdsale-widget" */).then(c => wrapFunctional(c.default || c))
export const ProjectsDotError = () => import('../../components/projects/DotError.vue' /* webpackChunkName: "components/projects-dot-error" */).then(c => wrapFunctional(c.default || c))
export const ProjectsProjectCard = () => import('../../components/projects/ProjectCard.vue' /* webpackChunkName: "components/projects-project-card" */).then(c => wrapFunctional(c.default || c))
export const ProjectsReportCollection = () => import('../../components/projects/ReportCollection.vue' /* webpackChunkName: "components/projects-report-collection" */).then(c => wrapFunctional(c.default || c))
export const ProjectsSandboxTag = () => import('../../components/projects/SandboxTag.vue' /* webpackChunkName: "components/projects-sandbox-tag" */).then(c => wrapFunctional(c.default || c))
export const AdminLayoutsAppbar = () => import('../../components/admin/layouts/Appbar.vue' /* webpackChunkName: "components/admin-layouts-appbar" */).then(c => wrapFunctional(c.default || c))
export const AdminLayoutsSidebar = () => import('../../components/admin/layouts/Sidebar.vue' /* webpackChunkName: "components/admin-layouts-sidebar" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
